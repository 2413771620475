import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Config } from '../config/config'

import Layout from '../layouts'

import ResultsSidenav from "../partials/results-sidenav"

import '../scss/list.scss'

import image from '../assets/images/results-image-min.jpg'
import publications from "../../static/all-results.yaml"

const ResultsPage = () => {
  return (
    <Layout>
      <div className="list-page results">

        <Helmet>
          <title>DigiWells – Results</title>
          <meta property="og:title" content="DigiWells – Results" />
          <meta name="description" content="See out latest presentations and publications." />
          <meta property="og:description" content="See out latest presentations and publications." />
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
        </Helmet>

        <div className="container">
          <ResultsSidenav />

          <div className="container-7">
            <h1 className="page-title">Latest results</h1>

            <div className="page-intro">
              {/* <img alt="" src={image} /> */}
              {/* <p>SFI DigiWells is committed to publishing in scientific peer reviewed journals with high impact factor and
                 present at conferences with peer reviewed abstract submissions. An estimated publication rate of 9 papers
                 per year, on average, should be within the scope of the centre. The centre will
                 identify and be present at strategically important conferences, to increase the impact of the research in the
                 scientific international community. An estimated 15 conferences per year, on average, will be attended,
                 including submission of conference papers.
              </p>
              <p>The project will work closely with the user partners and organize workshops meetings and demonstrations to present
                 project results.
              </p> */}
              The latest results from DigiWells presented to the scientific community:
            </div>

            <div className="list">
              {
                publications.results.map(function(publication, index) {

                  // const photo = require('../../assets/images/' + publication.image);
                  if (index < 7){
                  return (
                    <a className="list-item" href={publication.link.url} target="_blank" rel="noreferrer" key={index}>
                      <div className="list-item-text">
                        <h3 className=" list-item-title small">
                          {publication.title}
                          <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24px"  viewBox="0 -2 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                        </h3>
                        <div className="list-item-description publication">
                          {publication.description}
                        </div>
                        <div className="list-item-description details">​​
                          <span className="authors">
                            {publication.authors}
                          </span>
                          <br/>
                          <span>
                            {publication.link.display}
                            </span>
                        </div>
                      </div>

                    </a>
                  )
                  }
                  else{
                    return "";
                  }

                })
              }
            </div>

            {/* <p>Last updated: {publications.time ? publications.time : 'recently'}</p> */}



          </div>
        </div>

      </div>
    </Layout>
  )
}

export default ResultsPage
